
// export const localUrl = 'https://vldo.shop/'
// export const serverUrl = 'https://api.vldo.shop/'
export const localUrl = 'https://test.vldo.shop/'
export const serverUrl = 'https://apitest.vldo.shop/'


// export const localUrl = 'http://localhost:8080/'
// export const serverUrl = 'http://localhost:8000/'

export const currency = "$";